import {useMemo} from "react";
import {useSearchParams} from "react-router-dom";
import useSWR from "swr";
import usePaginationContext from "./use-pagination-context";

export default function usePagination() {
  const {params = {}, url} = usePaginationContext();
  const [searchParams] = useSearchParams();
  const query = useMemo(() => (
    {...params, ...Object.fromEntries(searchParams)}
  ), [params, searchParams]);
  const {data, isLoading, mutate} = useSWR({url, params: query});

  return {
    limit: query.limit || data?.limit,
    loading: isLoading,
    mutate,
    number: +(query.page || data?.number || 1),
    pages: +(data?.pages || 0),
    query,
    records: data?.records,
    search: data?.search,
    total: +(data?.total || 0)
  };
}
