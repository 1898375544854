import PropTypes from "prop-types";

function Select({options, placeholder = "None", ...props}) {
  return (
    <select {...props}>
      <option value="">{placeholder}</option>
      {options.map(({name, value}) => (
        <option key={value} value={value}>{name}</option>
      ))}
    </select>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  placeholder: PropTypes.string
};

export default Select;
