import Pagination from "components/helpers/pagination";
import Row from "./row";

function Heading() {
  return (
    <tr>
      <th>Section</th>
      <th>Content</th>
      <th>Actions</th>
    </tr>
  );
}

export default function List() {
  return (
    <div className="max-w-screen-lg mx-auto">
      <h1 className="text-center">Content</h1>
      <Pagination url="/api/admin/sections" Heading={Heading} Row={Row} />
    </div>
  );
}
