import PropTypes from "prop-types";
import {useEffect, useMemo, useState} from "react";
import {Context} from "lib/hooks/use-pagination";
import Container from "./container";

function Pagination({filters = {}, search = false, ..._props}) {
  const [params, setParams] = useState(filters.params || {});
  const props = useMemo(() => ({
    ..._props,
    filters,
    params,
    search,
    setParams
  }), [_props, params, search]);

  useEffect(() => {
    setParams(filters.params || {});
  }, [filters.params]);

  return (
    <Context.Provider value={props}>
      <Container />
    </Context.Provider>
  );
}

Pagination.propTypes = {
  filters: PropTypes.shape({
    params: PropTypes.shape({})
  }),
  search: PropTypes.bool,
  url: PropTypes.string.isRequired
};

export default Pagination;
