import {useCallback, useState} from "react";
import {generatePath, useParams} from "react-router-dom";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import {createNotification} from "app/actions/notifications";
import {valueFrom, valueFromTarget} from "lib/form";
import useAppContext from "lib/hooks/use-app-context";
import usePrompt from "lib/hooks/use-prompt";

export default function useEdit({
  mapSubmit,
  onUpdate,
  record: _record,
  withFiles = false,
  url: _url
}) {
  const [block, setBlock] = useState(false);
  const [changes, setChanges] = useState({});
  const {dispatch} = useAppContext();
  const params = useParams();
  const url = generatePath(_url, params);
  const {data: _data, isLoading: loading, mutate} = useSWR(_record ? null : {url});
  const {trigger} = useSWRMutation({method: "PUT", url});
  const record = _record || (loading ? null : _data.record);

  usePrompt({when: block});

  const onChange = useCallback(({target}) => {
    setBlock(true);
    setChanges((currentChanges) => ({
      ...currentChanges, [target.name]: valueFromTarget(target)
    }));
  }, []);

  const onChanges = useCallback((updatedChanges) => {
    setBlock(true);
    setChanges(updatedChanges);
  }, []);

  const onSubmit = useCallback((e) => {
    if(!withFiles) { e.preventDefault(); }

    const files = withFiles ? e : [];
    const updates = mapSubmit ? mapSubmit({changes, files}) : {...changes, ...files};

    if(Object.keys(updates).length === 0) {
      return dispatch(createNotification({
        content: "Please make changes before submitting",
        type: "error"
      }));
    }

    setBlock(false);
    trigger({params: {record: updates}}).then((data) => {
      dispatch(createNotification({content: data.message, type: data.success ? "success" : "error"}));
      if(!data.success) { return; }

      onUpdate ? onUpdate(data) : mutate({data: {record: data.record}});
    });
  }, [changes, trigger]);

  const value = useCallback((name, defaultValue) => (
    valueFrom({defaultValue, name, objects: [changes, record]})
  ), [changes, record]);

  return {changes, loading, onChange, onChanges, onSubmit, record, value};
}
