import {useParams} from "react-router-dom";
import useSWR from "swr";
import Content from "components/helpers/action-text/content";
import Candidates from "components/helpers/candidates/list";
import Loading from "components/pages/loading";
import {isBlankHTML} from "lib/ext/string";

export default function Show() {
  const {id} = useParams();
  const {data, isLoading: loading} = useSWR({url: `/api/public/tags/${id}`});
  const record = loading ? null : data.record;
  const params = {tag_ids: id};
  if(loading) { return <Loading />; }

  return (
    <div className="container">
      <div className="max-w-screen-lg mx-auto">
        <h1 className="text-center">{record.name}</h1>
        {!isBlankHTML(record.content) && (
          <div className="custom-html mb-4">
            <Content html={record.content} />
          </div>
        )}
        <Candidates params={params} />
      </div>
    </div>
  );
}
