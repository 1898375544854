import {useNew as useForm} from "lib/hooks/use-form";
import Form from "./form";

const defaults = {
  active: true,
  name: "",
  rank: 100,
  template: "Default",
  path: ""
};

export default function New() {
  const {onChange, onSubmit, value} = useForm({defaults, path: "/admin/tags/:id", url: "/api/admin/tags"});

  return (
    <>
      <h1 className="text-center">New Tag</h1>
      <Form onChange={onChange} onSubmit={onSubmit} value={value} />
    </>
  );
}
