/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from "prop-types";

function Label({children, tooltip = null, ...props}) {
  return (
    <label {...props}>
      {children}
      {tooltip && <span className="tooltip">{tooltip}</span>}
    </label>
  );
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string
};

export default Label;
