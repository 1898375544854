import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";
import useDidUpdate from "lib/hooks/use-did-update";

function Modal({children, onClose, show = true, title}) {
  const location = useLocation();

  useDidUpdate(() => { onClose(); }, [location.pathname]);

  if(!show) { return null; }

  return (
    <>
      <div className="fixed flex inset-0 items-center justify-center max-h-full overflow-x-hidden overflow-y-auto z-50 w-full">
        <div className="p-4 w-full max-h-full max-w-xl mx-auto">
          <div className="bg-light mb-8 rounded">
            <div className="border-b flex items-center justify-between px-6 py-4 relative">
              <h6 className="font-semibold">{title}</h6>
              {onClose && (
                <button aria-label="Close" className="absolute btn-icon inset-y-0 right-0 flex items-center mr-6" onClick={onClose} type="button">
                  <i className="fas fa-times" />
                </button>
              )}
            </div>
            <div className="p-6">
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 bg-dark bg-opacity-40 z-40" />
    </>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  title: PropTypes.node.isRequired
};

export default Modal;
