import PropTypes from "prop-types";
import MultiSelect from "./multi";
import SingleSelect from "./single";

function Select({multiple = false, options: _options, value: _value, ...props}) {
  const options = _options.map(((option) => (
    (typeof option === "object" && option !== null)
      ? {name: option.name, value: option.value?.toString()}
      : {name: option, value: option?.toString()}
  )));
  const value = multiple ? _value.map((v) => v.toString()) : _value?.toString();

  return multiple
    ? <MultiSelect options={options} value={value} {...props} />
    : <SingleSelect options={options} value={value} {...props} />;
}

Select.propTypes = {
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
      }).isRequired,
      PropTypes.string.isRequired
    ]).isRequired
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]).isRequired
};

export default Select;
