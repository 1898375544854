export default function Footer() {
  return (
    <nav className="footer">
      <div className="container">
        <div className="flex items-center justify-center">
          <p className="mb-1">Website by <a className="btn-tomify btn-sm" href="https://app.tomify.me" rel="noopener noreferrer" target="_blank">Tomify</a></p>
        </div>
      </div>
    </nav>
  );
}
