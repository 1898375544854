import {useMemo} from "react";
import {Link} from "react-router-dom";
import Pagination from "components/helpers/pagination";
import Row from "./row";

function Heading() {
  return (
    <tr>
      <th>Active</th>
      <th>Name</th>
      <th>Coordinator</th>
      <th>Identifier</th>
      <th>Location</th>
      <th>Priority</th>
      <th>Sponsor</th>
      <th>Tags</th>
      <th>Created At</th>
      <th>Actions</th>
    </tr>
  );
}

const getDropdown = (key) => ({
  active: {
    label: "Active",
    name: key,
    options: [{name: "Yes", value: "true"}, {name: "No", value: "false"}]
  },
  sponsored: {
    label: "Sponsored",
    name: key,
    options: [{name: "Yes", value: "true"}, {name: "No", value: "false"}]
  }
}[key]);

export default function List() {
  const filters = useMemo(() => ({
    fields: ["active", "sponsored"].map((key) => getDropdown(key))
  }), []);

  return (
    <div className="max-w-screen-xl mx-auto">
      <h1 className="text-center">Candidates</h1>
      <div className="text-right mb-1">
        <Link className="btn btn-outline-black btn-sm" to="/admin/candidates/new">New Candidate</Link>
      </div>
      <Pagination filters={filters} search={true} url="/api/admin/candidates" Heading={Heading} Row={Row} />
    </div>
  );
}
