import {useNavigate} from "react-router-dom";
import {setUser} from "app/actions/user";
import useAppContext from "lib/hooks/use-app-context";
import {useNew as useForm} from "lib/hooks/use-form";
import useQueryParams from "lib/hooks/use-query-params";

const defaults = {
  first_name: "",
  last_name: "",
  password: "",
  password_confirmation: ""
};

export default function Register() {
  const {dispatch} = useAppContext();
  const navigate = useNavigate();
  const params = useQueryParams();
  const onCreate = (data) => {
    dispatch(setUser(data.record));

    const candidateID = params.get("candidate_id");
    if(candidateID) { navigate(`/candidates/${candidateID}`); }
  };
  const {onChange, onSubmit, value} = useForm({
    defaults: {...defaults, email: params.get("email") || ""},
    onCreate,
    url: "/api/public/user"
  });

  return (
    <div className="container">
      <h1 className="text-center">Register</h1>
      <div className="max-w-sm mx-auto">
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <input
              id="register-email"
              name="email"
              onChange={onChange}
              placeholder="Email"
              required={true}
              type="email"
              value={value("email")}
            />
          </div>
          <div className="mb-4">
            <input
              id="register-first-name"
              name="first_name"
              onChange={onChange}
              placeholder="First Name"
              required={true}
              type="text"
              value={value("first_name")}
            />
          </div>
          <div className="mb-4">
            <input
              id="register-last-name"
              name="last_name"
              onChange={onChange}
              placeholder="Last Name"
              required={true}
              type="text"
              value={value("last_name")}
            />
          </div>
          <div className="mb-4">
            <input
              id="register-password"
              name="password"
              onChange={onChange}
              placeholder="Password (optional)"
              type="password"
              value={value("password")}
            />
          </div>
          <div className="mb-4">
            <input
              id="register-password-confirmation"
              name="password_confirmation"
              onChange={onChange}
              placeholder="Password Confirmation (optional)"
              type="password"
              value={value("password_confirmation")}
            />
          </div>
          <div className="text-center">
            <button className="btn btn-blue mx-2" type="submit">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  );
}
