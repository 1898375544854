import PropTypes from "prop-types";
import {useState} from "react";
import Checkbox from "components/helpers/form/checkbox";
import ContentEditor from "components/helpers/form/content-editor";
import DateField from "components/helpers/form/date";
import File from "components/helpers/form/file";
import Label from "components/helpers/form/label";
import Select from "components/helpers/form/select";
import SelectWithSearch from "components/helpers/form/select/with-search";
import FormWithFiles from "components/helpers/form/with-files";
import {valueFromTarget} from "lib/form";

function Form({onChange, onSubmit, value}) {
  const [show, setShow] = useState(false);
  const onShow = () => setShow(!show);

  const data = value("data", {});
  const onDataChange = ({target}) => {
    const changes = {...data, [target.name]: valueFromTarget(target)};

    onChange({target: {name: "data", type: "object", value: changes}});
  };

  return (
    <div className="max-w-sm mx-auto">
      <FormWithFiles onSubmit={onSubmit}>
        <div className="mb-4">
          <Checkbox
            checked={value("active", true)}
            id="record-active"
            label="Active"
            name="active"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-coordinator">Coordinator</label>
          <SelectWithSearch
            id="record-coordinator"
            name="coordinator_id"
            onChange={onChange}
            optionText={(record) => [record.first_name, record.last_name].filter(Boolean).join(" ")}
            optionValue={(record) => record.id.toString()}
            request={{url: "/api/admin/users", params: {coordinator: true, limit: -1}}}
            value={value("coordinator_id")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-first-name">First Name</label>
          <input
            id="record-first-name"
            name="first_name"
            onChange={onChange}
            placeholder="Fatma"
            required={true}
            type="text"
            value={value("first_name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-last-name">Last Name</label>
          <input
            id="record-last-name"
            name="last_name"
            onChange={onChange}
            placeholder="Prats"
            required={true}
            type="text"
            value={value("last_name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-image">Image</label>
          <File id="record-image" name="image" onChange={onChange} />
          {value("image") && <img alt="Profile" src={value("image")} />}
        </div>
        <div className="mb-4">
          <label htmlFor="record-sponsor">Sponsor</label>
          <SelectWithSearch
            id="record-sponsor"
            name="sponsor_id"
            onChange={onChange}
            optionText={(record) => [record.first_name, record.last_name].filter(Boolean).join(" ")}
            optionValue={(record) => record.id.toString()}
            request={{url: "/api/admin/users", params: {limit: -1}}}
            value={value("sponsor_id")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-tags">Tags</label>
          <SelectWithSearch
            id="record-tags"
            multiple={true}
            name="tag_ids"
            onChange={onChange}
            optionText={(record) => record.name}
            optionValue={(record) => record.id.toString()}
            placeholder="Select Tag"
            request={{url: "/api/admin/tags", params: {limit: -1}}}
            value={value("tag_ids", [])}
          />
        </div>
        {show && (
          <>
            <div className="text-center">
              <button className="btn btn-green" onClick={onShow} type="button">Show {show ? "Less" : "More"}</button>
            </div>
            <div className="mb-4">
              <label htmlFor="record-birthdate">Birthdate</label>
              <DateField
                id="record-birthdate"
                name="birthdate"
                onChange={onChange}
                value={value("birthdate")}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="record-identifier">Identifier</label>
              <input
                id="record-identifier"
                name="identifier"
                onChange={onChange}
                placeholder="N2N-F-TENT-C42-24-12-05-0022"
                type="text"
                value={value("identifier")}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="record-location">Location</label>
              <input
                id="record-location"
                name="location"
                onChange={onChange}
                placeholder="Tent C42"
                type="text"
                value={value("location")}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="record-phone-number">Phone Number</label>
              <input
                id="record-phone-number"
                name="phone_number"
                onChange={onChange}
                placeholder="Phone Number"
                type="tel"
                value={value("phone_number")}
              />
            </div>
            <div className="mb-4">
              <Label htmlFor="record-priority" tooltip="The lower the priority, the higher the record is shown">Priority</Label>
              <input
                id="record-priority"
                name="priority"
                onChange={onChange}
                placeholder="100"
                type="text"
                value={value("priority")}
              />
            </div>
            {[
              {id: "favorite-color", key: "favorite_color", label: "Favorite Color", placeholder: "Green"},
              {id: "favorite-food", key: "favorite_food", label: "Favorite Food", placeholder: "Burritos"},
              {id: "favorite-subject", key: "favorite_subject", label: "Favorite Subject", placeholder: "Math"},
              {id: "future-occupation", key: "future_occupation", label: "Future Occupation", placeholder: "Doctor"},
              {id: "grade", key: "grade", label: "Grade", placeholder: "Grade"},
              {id: "hobby", key: "hobby", label: "Hobby", placeholder: "Napping"}
            ].map(({id, key, label, placeholder}) => (
              <div key={key} className="mb-4">
                <label htmlFor={`record-${id}`}>{label}</label>
                <input
                  id={`record-${id}`}
                  name={key}
                  onChange={onDataChange}
                  placeholder={placeholder}
                  type="text"
                  value={data[key] || ""}
                />
              </div>
            ))}
            <div className="mb-4">
              <label htmlFor="record-last-name">Sex</label>
              <Select
                id="record-sex"
                name="sex"
                onChange={onDataChange}
                options={["Male", "Female", "Other"]}
                placeholder="Select Sex"
                value={data.sex || ""}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="record-story-disabled">Story</label>
              <ContentEditor
                id="record-story"
                name="story"
                onChange={onChange}
                value={value("story")}
              />
            </div>
            <div className="mb-4">
              <Label htmlFor="record-notes-disabled" tooltip="Only visible by admin and coordinators">Notes</Label>
              <ContentEditor
                id="record-notes"
                name="notes"
                onChange={onChange}
                value={value("notes")}
              />
            </div>
          </>
        )}
        <div className="text-center">
          <button className="btn btn-green mr-2" onClick={onShow} type="button">Show {show ? "Less" : "More"}</button>
          <button className="btn btn-blue" type="submit">Submit</button>
        </div>
      </FormWithFiles>
    </div>
  );
}

Form.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default Form;
