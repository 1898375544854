import PropTypes from "prop-types";
import {useMemo} from "react";
import Pagination from "components/helpers/pagination";
import Content from "./content";

function List({params = {}}) {
  const filters = useMemo(() => ({params}), [params]);

  return (
    <Pagination filters={filters} search={true} url="/api/public/candidates" Content={Content} />
  );
}

List.propTypes = {params: PropTypes.shape({tag_id: PropTypes.string})};

export default List;
