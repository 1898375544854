import Content from "components/helpers/action-text/content";
import useAppContext from "lib/hooks/use-app-context";
import List from "components/helpers/candidates/list";
import {isBlankHTML} from "lib/ext/string";

export default function Sponsored() {
  const {page} = useAppContext();

  return (
    <div className="container">
      <div className="max-w-screen-lg mx-auto">
        {!isBlankHTML(page.content) && (
          <div className="custom-html">
            <Content html={page.content} />
          </div>
        )}
        <List params={{sponsored: true}} />
      </div>
    </div>
  );
}
