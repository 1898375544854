import PropTypes from "prop-types";
import defaultImage from "app/assets/images/no-image.jpg";
import Content from "components/helpers/action-text/content";
import {isBlankHTML} from "lib/ext/string";

function Candidate({className = "", record}) {
  return (
    <div className={[className, "flex flex-col gap-2 items-center"].filter(Boolean).join(" ")}>
      <div className="flex gap-4 w-full">
        <img alt={record.name} className="max-w-[100px] md:max-w-[200px] rounded" src={record.image || defaultImage} />
        <div>
          <h6 className="font-semibold">{record.name}</h6>
          {[
            {label: "Birthdate", value: record.birthdate},
            {label: "Favorite Color", value: record.data.favorite_color},
            {label: "Favorite Food", value: record.data.favorite_food},
            {label: "Favorite Subject", value: record.data.favorite_subject},
            {label: "Future Occupation", value: record.data.future_occupation},
            {label: "Grade", value: record.data.grade},
            {label: "Hobby", value: record.data.hobby}
          ].filter(({value}) => value).map(({label, value}) => (
            <div key={label}><b>{label}:</b> {value}</div>
          ))}
        </div>
      </div>
      {!isBlankHTML(record.story) && <Content className="w-full" html={record.story} />}
    </div>
  );
}

Candidate.propTypes = {
  className: PropTypes.string,
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    birthdate: PropTypes.string,
    data: PropTypes.shape({
      favorite_color: PropTypes.string,
      favorite_food: PropTypes.string,
      favorite_subject: PropTypes.string,
      future_occupation: PropTypes.string,
      grade: PropTypes.string,
      hobby: PropTypes.string
    }),
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    story: PropTypes.string
  }).isRequired
};

export default Candidate;
