import PropTypes from "prop-types";
import Select from "components/helpers/form/select";
import {booleanFromTarget, valueFromTarget} from "lib/form";
import {usePaginationContext} from "lib/hooks/use-pagination";

function Dropdown({
  id: _id = null,
  label,
  name,
  placeholder = "Both",
  ...props
}) {
  const id = _id || `list-${name}`;

  return (
    <div className="gap-2 flex items-center">
      <label htmlFor={id}>{label}</label>
      <Select
        className="grow md:grow-0 w-auto"
        id={id}
        name={name}
        placeholder={placeholder || "Both"}
        {...props}
      />
    </div>
  );
}

Dropdown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

export default function Filters() {
  const {filters, params, setParams} = usePaginationContext();
  const onChange = ({target}) => {
    setParams((currentParams) => ({
      ...currentParams, [target.name]: booleanFromTarget(valueFromTarget(target))
    }));
  };
  const value = (name) => params[name]?.toString() || "";

  return (
    <div className="flex flex-col md:flex-row gap-4">
      {(filters.fields || []).map((field) => (
        <Dropdown key={field.name} onChange={onChange} value={value(field.name)} {...field} />
      ))}
    </div>
  );
}
