/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types";
import Select from "./select";

function MultiSelect({options: _options, value: values, ...props}) {
  const options = _options.map(((option) => (
    (typeof option === "object" && option !== null)
      ? option
      : {name: option, value: option}
  )));

  return (
    <>
      {values.map((_, index) => (
        <Select
          key={index}
          index={index}
          options={options}
          values={values}
          {...props}
        />
      ))}
      <Select
        index={values.length}
        options={options}
        values={values}
        {...props}
      />
    </>
  );
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      }),
      PropTypes.string
    ]).isRequired
  ).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MultiSelect;
