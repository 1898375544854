import {useState} from "react";
import {Link} from "react-router-dom";
import useSWRMutation from "swr/mutation";
import {createNotification} from "app/actions/notifications";
import {setUser} from "app/actions/user";
import Checkbox from "components/helpers/form/checkbox";
import File from "components/helpers/form/file";
import FormWithFiles from "components/helpers/form/with-files";
import {valueFrom, valueFromTarget} from "lib/form";
import useAppContext from "lib/hooks/use-app-context";

const url = "/api/public/user";

export default function Profile() {
  const {dispatch, user} = useAppContext();
  const [changes, setChanges] = useState({});
  const {trigger: triggerDelete} = useSWRMutation({method: "DELETE", url});
  const {trigger} = useSWRMutation({method: "PUT", url});

  const onChange = ({target}) => (
    setChanges({...changes, [target.name]: valueFromTarget(target)})
  );
  const onDelete = () => {
    if(!window.confirm("Are you sure you want to permanently delete your account?")) { return; }

    triggerDelete().then((data) => {
      if(data.success) {
        window.location.href = `/?message=${data.message}&type=success`;
      } else {
        dispatch(createNotification({content: data.message, type: "error"}));
      }
    });
  };
  const onSubmit = (files) => {
    const updates = {...changes, ...files};

    if(Object.keys(updates).length === 0) {
      return dispatch(createNotification({
        content: "Please make changes before submitting",
        type: "error"
      }));
    }

    trigger({params: {record: updates}}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "error"
      }));

      if(data.success) {
        dispatch(setUser(data.record));
        setChanges({});
      }
    });
  };
  const value = (name, defaultValue) => valueFrom({defaultValue, name, objects: [changes, user]});

  return (
    <div className="container">
      <h1 className="text-center">Profile</h1>
      <div className="flex justify-center mb-4">
        <Link className="btn btn-blue" to="/sponsorships">View Sponsorships</Link>
      </div>
      <div className="max-w-sm mx-auto">
        <FormWithFiles onSubmit={onSubmit}>
          <div className="mb-4">
            <input
              id="user-first-name"
              name="first_name"
              onChange={onChange}
              placeholder="First Name"
              required={true}
              type="text"
              value={value("first_name")}
            />
          </div>
          <div className="mb-4">
            <input
              id="user-last-name"
              name="last_name"
              onChange={onChange}
              placeholder="Last Name"
              required={true}
              type="text"
              value={value("last_name")}
            />
          </div>
          <div className="mb-4">
            <input
              id="user-email"
              name="email"
              onChange={onChange}
              placeholder="Email"
              required={true}
              type="email"
              value={value("email")}
            />
          </div>
          <div className="mb-4">
            <input
              id="user-password"
              name="password"
              onChange={onChange}
              placeholder="Password"
              type="password"
              value={changes.password || ""}
            />
          </div>
          <div className="mb-4">
            <input
              id="user-password-confirmation"
              name="password_confirmation"
              onChange={onChange}
              placeholder="Password Confirmation"
              type="password"
              value={changes.password_confirmation || ""}
            />
          </div>
          <div className="mb-4">
            <File id="user-image" name="image" onChange={onChange} />
            {value("image") && <img alt="Profile" src={value("image")} />}
          </div>
          <div className="mb-4">
            <Checkbox
              checked={"notifications" in changes ? changes.notifications : user.notifications}
              id="user-notifications"
              label="Notifications"
              name="notifications"
              onChange={onChange}
            />
          </div>
          <div className="text-center">
            <button className="btn btn-blue mr-2 px-5" type="submit">Save Profile</button>
            <button className="btn btn-red px-5" onClick={onDelete} type="button">Delete Profile</button>
          </div>
        </FormWithFiles>
      </div>
    </div>
  );
}
