import Loading from "components/pages/loading";
import usePagination, {usePaginationContext} from "lib/hooks/use-pagination";
import Filters from "./filters";
import Navigation from "./navigation";
import Search from "./search";
import Table from "./table";

export default function Container() {
  const {loading} = usePagination();
  const {filters, search, Content} = usePaginationContext();
  if(loading) { return <Loading />; }

  const fields = filters.fields || [];

  return (
    <div>
      {fields.length > 0 && (
        <div className="gap-2 flex flex-col md:flex-row justify-between my-2">
          <Filters />
          <div />
        </div>
      )}
      <div className="gap-2 flex flex-col md:flex-row justify-between my-2">
        {search ? <Search /> : <div />}
        <Navigation />
      </div>
      {Content ? <Content /> : <Table />}
      <div className="gap-2 flex flex-col md:flex-row justify-end my-2">
        <Navigation />
      </div>
    </div>
  );
}
