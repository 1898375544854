import PropTypes from "prop-types";

function DateField({onChange, value = null, ...props}) {
  return (
    <input
      className="form-control"
      onChange={onChange}
      type="date"
      value={value || ""}
      {...props}
    />
  );
}

DateField.propTypes = {onChange: PropTypes.func.isRequired, value: PropTypes.string};

export default DateField;
