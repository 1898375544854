import PropTypes from "prop-types";
import useSWR from "swr";
import Select from "./index";

function SelectWithSearch({optionText, optionValue, request, ...props}) {
  const {data, isLoading: loading} = useSWR(request);
  if(loading) { return <Select options={[]} placeholder="Loading" {...props} />; }

  const options = data.records.map((record) => ({
    name: optionText(record),
    value: optionValue(record)
  }));

  return <Select options={options} {...props} />;
}

SelectWithSearch.propTypes = {
  optionText: PropTypes.func.isRequired,
  optionValue: PropTypes.func.isRequired,
  request: PropTypes.shape({url: PropTypes.string.isRequired}).isRequired
};

export default SelectWithSearch;
