import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Check from "components/helpers/check";
import {displayDateTime} from "lib/ext/string";
import {useDelete} from "lib/hooks/use-pagination";

function Row({record}) {
  const onDelete = useDelete({check: "Are you sure you want to delete this candidate?", record});

  return (
    <tr>
      <td><Check checked={record.active} /></td>
      <td>{[record.first_name, record.last_name].filter(Boolean).join(" ")}</td>
      <td>{record.coordinator ? record.coordinator.name : "None"}</td>
      <td>{record.identifier}</td>
      <td>{record.location || "None"}</td>
      <td>{record.priority}</td>
      <td>{record.sponsor ? record.sponsor.name : "None"}</td>
      <td>
        {record.tags.map((tag) => (
          <span key={tag.id} className="px-1">{tag.name}</span>
        ))}
        {record.tags.length === 0 && "None"}
      </td>
      <td>{displayDateTime(record.created_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <Link className="btn btn-sm btn-blue" to={`/admin/candidates/${record.id}`}>Edit</Link>
          <button type="button" className="btn btn-sm btn-red" onClick={onDelete}>Destroy</button>
        </div>
      </td>
    </tr>
  );
}

Row.propTypes = {
  record: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    coordinator: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }),
    created_at: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    identifier: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    location: PropTypes.string,
    priority: PropTypes.number.isRequired,
    sponsor: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired
};

export default Row;
