import {Link} from "react-router-dom";
import Pagination from "components/helpers/pagination";
import Row from "./row";

function Heading() {
  return (
    <tr>
      <th>Active</th>
      <th>Name</th>
      <th>Coordinator</th>
      <th>Candidates</th>
      <th>Rank</th>
      <th>Created At</th>
      <th>Actions</th>
    </tr>
  );
}

export default function List() {
  return (
    <div className="max-w-screen-lg mx-auto">
      <h1 className="text-center">Tags</h1>
      <div className="text-right mb-1">
        <Link className="btn btn-outline-black btn-sm" to="/admin/tags/new">New Tag</Link>
      </div>
      <Pagination search={true} url="/api/admin/tags" Heading={Heading} Row={Row} />
    </div>
  );
}
