import PropTypes from "prop-types";
import Checkbox from "components/helpers/form/checkbox";
import ContentEditor from "components/helpers/form/content-editor";
import Label from "components/helpers/form/label";
import Select from "components/helpers/form/select/with-search";

function Form({onChange, onSubmit, value}) {
  return (
    <div className="max-w-sm mx-auto">
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <Checkbox
            checked={value("active", true)}
            id="record-active"
            label="Active"
            name="active"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-coordinator">Coordinator</label>
          <Select
            id="record-coordinator"
            name="coordinator_id"
            onChange={onChange}
            optionText={(record) => [record.first_name, record.last_name].filter(Boolean).join(" ")}
            optionValue={(record) => record.id}
            request={{url: "/api/admin/users", params: {coordinator: true, limit: -1}}}
            value={value("coordinator_id")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-name">Name</label>
          <input
            id="record-name"
            name="name"
            onChange={onChange}
            placeholder="Orphans"
            required={true}
            type="text"
            value={value("name")}
          />
        </div>
        <div className="mb-4">
          <Label htmlFor="record-rank" tooltip="The lower the rank, the higher the record is shown">Rank</Label>
          <input
            id="record-rank"
            name="rank"
            onChange={onChange}
            placeholder="100"
            required={true}
            type="text"
            value={value("rank")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-content-disabled">Content</label>
          <ContentEditor
            id="record-content"
            name="content"
            onChange={onChange}
            value={value("content")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-notes-disabled">Notes</label>
          <ContentEditor
            id="record-notes"
            name="notes"
            onChange={onChange}
            value={value("notes")}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </form>
    </div>
  );
}

Form.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default Form;
