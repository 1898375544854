import PropTypes from "prop-types";

function Select({
  id: _id,
  index,
  name,
  onChange,
  options: _options,
  placeholder = "None",
  values
}) {
  const id = index === 0 ? _id : `${_id}-${index}`;
  const onSelect = ({target: {type, value: updatedValue}}) => {
    const updatedValues = [...values];

    updatedValue === ""
      ? updatedValues.splice(index, 1)
      : updatedValues.splice(index, 1, updatedValue);

    onChange({target: {name, type, value: updatedValues}});
  };
  const options = _options.filter((option) => {
    if(!values.includes(option.value)) { return true; }

    return option.value === values[index];
  });

  if(options.length === 0) { return null; }

  return (
    <select id={id} name={name} onChange={onSelect} value={values[index] || ""}>
      <option value="">{placeholder}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>{option.name}</option>
      ))}
    </select>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  placeholder: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Select;
