import {useLocation} from "react-router-dom";
import NavLink from "components/helpers/nav-link";

const nestedRoutes = {
  candidates: {
    home: {name: "Candidates", path: "/admin/candidates"},
    routes: [
      {name: "Candidates", path: "/admin/candidates"},
      {name: "Tags", path: "/admin/tags"}
    ]
  },
  users: {
    home: {name: "Users", path: "/admin/users"},
    routes: [
      {name: "All", path: "/admin/users"},
      {name: "Admins", path: "/admin/admins"},
      {name: "Coordinators", path: "/admin/coordinators"},
      {name: "Sponsors", path: "/admin/sponsors"}
    ]
  },
  settings: {
    home: {name: "Settings", path: "/admin/settings"},
    routes: [
      {name: "Content", path: "/admin/sections"},
      {name: "Pages", path: "/admin/pages"}
    ]
  }
};

export default function AdminNavbar() {
  const {pathname} = useLocation();
  const secondaryRoutes = Object.keys(nestedRoutes)
    .filter((key) => nestedRoutes[key].routes.some(({path}) => pathname.startsWith(path)));

  return (
    <>
      <nav className="bg-dark bg-green">
        <div className="container py-2">
          <div className="flex items-center justify-between">
            <NavLink to="/admin">Admin</NavLink>
            <div className="flex flex-wrap gap-4 justify-end">
              <NavLink active={secondaryRoutes.includes("candidates")} to="/admin/candidates">Candidates</NavLink>
              <NavLink active={secondaryRoutes.includes("settings")} to="/admin/settings">Settings</NavLink>
              <NavLink active={secondaryRoutes.includes("users")} to="/admin/users">Users</NavLink>
            </div>
          </div>
        </div>
      </nav>
      {secondaryRoutes.map((key) => (
        <div key={key} className="bg-dark bg-darkGray">
          <div className="container py-2">
            <div className="flex items-center justify-between">
              <NavLink to={nestedRoutes[key].home.path}>{nestedRoutes[key].home.name}</NavLink>
              <div className="flex flex-wrap gap-4 justify-end">
                {nestedRoutes[key].routes.map((route) => (
                  <NavLink key={route.name} to={route.path}>{route.name}</NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
