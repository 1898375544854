import {useMemo} from "react";
import {useLocation, Link} from "react-router-dom";
import Pagination from "components/helpers/pagination";
import Row from "./row";

function Heading() {
  return (
    <tr>
      <th>Admin</th>
      <th>Coordinator</th>
      <th>Email</th>
      <th>Name</th>
      <th>Candidates</th>
      <th>Created At</th>
      <th>Actions</th>
    </tr>
  );
}

const getData = (path) => ({
  "/admin/admins": {heading: "Admins", params: {admin: true}},
  "/admin/coordinators": {heading: "Coordinators", params: {coordinator: true}},
  "/admin/sponsors": {dropdowns: ["sponsoring"], heading: "Sponsors", params: {coordinator: false, sponsoring: true}}
}[path] || {dropdowns: ["sponsoring"], heading: "Users", params: {}});

const getDropdown = (key) => ({
  sponsoring: {
    label: "Sponsoring",
    name: key,
    options: [{name: "Yes", value: "true"}, {name: "No", value: "false"}]
  }
}[key]);

export default function List() {
  const location = useLocation();
  const data = useMemo(() => getData(location.pathname), [location.pathname]);
  const filters = useMemo(() => data.dropdowns && ({
    fields: data.dropdowns.map((key) => getDropdown(key)),
    params: data.params
  }), [data]);

  return (
    <div className="container">
      <div className="max-w-screen-lg mx-auto">
        <h1 className="text-center">{data.heading}</h1>
        <div className="gap-2 flex flex-col-reverse md:items-center md:flex-row justify-center mb-1">
          <Link className="btn btn-outline-black btn-sm text-center" to="/admin/users/new">Invite User</Link>
        </div>
        <Pagination filters={filters} search={true} url="/api/admin/users" Heading={Heading} Row={Row} />
      </div>
    </div>
  );
}
