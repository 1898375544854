import {useNew as useForm} from "lib/hooks/use-form";
import Form from "./form";

const defaults = {
  active: true,
  data: {},
  first_name: "",
  identifier: "",
  last_name: "",
  location: "",
  priority: 100,
  tag_ids: []
};

export default function New() {
  const {onChange, onSubmit, value} = useForm({defaults, path: "/admin/candidates/:id", withFiles: true, url: "/api/admin/candidates"});

  return (
    <>
      <h1 className="text-center">New Candidate</h1>
      <Form onChange={onChange} onSubmit={onSubmit} value={value} />
    </>
  );
}
