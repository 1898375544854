import {useState} from "react";
import {Link} from "react-router-dom";
import defaultImage from "app/assets/images/no-image.jpg";
import Content from "components/helpers/action-text/content";
import {isBlankHTML} from "lib/ext/string";
import usePagination from "lib/hooks/use-pagination";
import Modal from "./modal";

export default function Candidates() {
  const {query, records} = usePagination();
  const [modalProps, setModalProps] = useState({});
  const [showModal, setShowModal] = useState(false);

  const onClick = (e, {record}) => {
    e.preventDefault();
    setModalProps({record, sponsored: query.sponsored || false});
    setShowModal(true);
  };

  return (
    <>
      <div className="flex flex-col gap-8">
        {records.map((record) => (
          <button key={record.id} className="btn-none flex flex-col md:flex-row gap-4 md:items-center" href={`/candidates/${record.id}`} onClick={(e) => onClick(e, {record})} type="button">
            <div className="flex flex-1 gap-4">
              <img alt={record.name} className="max-w-[200px] rounded self-start" src={record.image || defaultImage} />
              <div className="flex flex-1 flex-col gap-4">
                <h3>{record.name}</h3>
                {query.sponsored ? (
                  <div>
                    <button className="btn-disabled btn-theme" disabled={true} type="button">Sponsored</button>
                  </div>
                ) : (
                  <>
                    {!isBlankHTML(record.story) && <Content className="w-full" html={record.story} />}
                    <div className="flex flex-wrap gap-4">
                      {record.tags.map((tag) => (
                        <Link key={tag.id} className="btn-blue btn-sm" to={`/tags/${tag.id}`}>{tag.name}</Link>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {!query.sponsored && (
              <div className="m-auto text-center">
                <Link className="btn-theme" to={`/candidates/${record.id}`}>Sponsor {record.name}</Link>
              </div>
            )}
          </button>
        ))}
      </div>
      {showModal && <Modal {...modalProps} onClose={() => setShowModal(false)} />}
    </>
  );
}
